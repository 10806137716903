// material-ui
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import LoadingPayment from 'components/animate/LoadingPayment';

const LoaderWrapper2 = styled('div')({
  position: 'fixed',
  top: '65px',
  left: 0,
  zIndex: 1301,
  width: '100%',
  height: 'calc(100% - 65px)',
  padding: '10px',
});

// ==============================|| LOADER ||============================== //
const Loader = () => (
  <LoaderWrapper2>
    {/* <LinearProgress color="primary" /> */}
    <Box
      sx={{
        background: '#FFFFFF',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '16px',
      }}
    >
      <LoadingPayment />
    </Box>
  </LoaderWrapper2>
);

export default Loader;
