import { combineReducers } from 'redux';
import storageSession from 'redux-persist/lib/storage/session';
// slices
import globalReducer from './slices/global';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage: storageSession,
  keyPrefix: 'redux-',
  whitelist: ['global', 'checkout'],
};

// console.log(rootPersistConfig);

const rootReducer = combineReducers({
  global: globalReducer,
});

export { rootPersistConfig, rootReducer };
