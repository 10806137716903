import { Outlet } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

// config
import main_bg from 'assets/images/bg_checkin.svg';

const RootStyle = styled('main')<any>(() => ({
  // padding: '24px',
  position: 'absolute',
  width: '100%',
  height: '100%',
  //   top: HEADER.DASHBOARD_DESKTOP_HEIGHT,
  //   padding: 10,
  //   right: 0,
  //   position: "absolute",
  //   borderRadius: "16px",
  //   width: "100%",
  //   height: `calc(100vh - ${HEADER.MOBILE_HEIGHT}px)`,
  //   zIndex: theme.zIndex.appBar + 1,
  //   background: "#F4F6FA",
  //   transition: theme.transitions.create(["width", "height"], {
  //     duration: theme.transitions.duration.shorter,
  //   }),
  //   [theme.breakpoints.up("lg")]: {
  //     width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px - 48px)`,
  //     height: `calc(100vh - ${HEADER.DASHBOARD_DESKTOP_HEIGHT}px)`,
  //   },
  //   [theme.breakpoints.down("lg")]: {
  //     top: HEADER.DASHBOARD_DESKTOP_HEIGHT,
  //     width: "calc(100%)",
  //   },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
        background: '#F4F6FA',
      }}
    >
      <RootStyle>
        <img
          src={main_bg}
          alt=""
          style={{
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            position: 'absolute',
            top: 0,
            right: 0,
          }}
        />
        <Box
          sx={{
            position: 'relative',
            // background: listPage.includes(location.pathname) ? "" : "#FFFFFF",
            width: '100%',
            height: '100%',
            borderRadius: '16px',
          }}
        >
          <Outlet />
        </Box>
      </RootStyle>
    </Box>
  );
}
