import axiosInstance from './axiosInstance';
import { BASE_URL, CATALOG_DOMAIN, STAFF_DOMAIN, CUSTOMER_DOMAIN, TENANT_DOMAIN, POS_DOMAIN } from 'services/appDomain';

const CATALOG_HOST = CATALOG_DOMAIN || 'https://xutv4ec32l.execute-api.ap-southeast-1.amazonaws.com/dev';

const STAFF_HOST = STAFF_DOMAIN || 'https://4i98pacai4.execute-api.ap-southeast-1.amazonaws.com/dev';

const CUSTOMER_HOST = CUSTOMER_DOMAIN || 'https://4i98pacai4.execute-api.ap-southeast-1.amazonaws.com/dev';

const BUSINESS_HOST = TENANT_DOMAIN || 'https://yiwrcb0o4g.execute-api.ap-southeast-1.amazonaws.com/dev';

const TENANT_HOST = TENANT_DOMAIN || 'https://yiwrcb0o4g.execute-api.ap-southeast-1.amazonaws.com/dev';

// const BASE_URL = BASE_URL;

// ========== SERVICE SETUP ==========
const getAllProducts = () => {
  return axiosInstance.get(`${CATALOG_HOST}/catalog/services`);
};
const createProduct = (data: any) => {
  return axiosInstance.post(`${CATALOG_HOST}/catalog/services`, { ...data });
};

const updateProduct = (data: any) => {
  return axiosInstance.put(`${CATALOG_HOST}/catalog/services`, { ...data });
};

const deleteProduct = (id: any) => {
  return axiosInstance.delete(`${CATALOG_HOST}/catalog/services`, {
    data: {
      productId: id,
    },
  });
};

const createCategory = (data: any) => {
  return axiosInstance.post(`${CATALOG_HOST}/catalog/categories`, { ...data });
};

const updateCategory = (data: any) => {
  return axiosInstance.put(`${CATALOG_HOST}/catalog/categories`, { ...data });
};

const deleteCategory = (id: any) => {
  return axiosInstance.delete(`${CATALOG_HOST}/catalog/categories`, {
    data: {
      categoryId: id,
    },
  });
};
// ========== END OF SERVICE SETUP ==========

// ========== STAFF MANAGEMENT ==========
const getAllStaff = () => {
  return axiosInstance.get(`${STAFF_HOST}/staffs`);
};

const getStaffDetails = (data: any) => {
  return axiosInstance.post(`${STAFF_HOST}/staffs/info`, { ...data });
};

const createStaff = (data: any) => {
  return axiosInstance.post(`${STAFF_HOST}/staffs`, { ...data }).catch((err: any) => {
    const listErr = err.response.data?.details;
    throw new Error((Array.isArray(listErr) && listErr.join(', ')) || 'Something went wrong!');
  });
};

const updateStaff = (data: any) => {
  return axiosInstance.put(`${STAFF_HOST}/staffs/info`, { ...data }).catch((err: any) => {
    const listErr = err.response.data?.details;
    throw new Error((Array.isArray(listErr) && listErr.join(', ')) || 'Something went wrong!');
  });
};

const updateStaffCommission = (data: any) => {
  return axiosInstance.put(`${STAFF_HOST}/staffs/commission`, { ...data });
};

const deleteStaff = (data: any) => {
  return axiosInstance.post(`${STAFF_HOST}/staffs/info/remove`, { ...data });
};

const getRoleAndPermission = () => {
  return axiosInstance.get(`${BASE_URL}/roles/permission`);
};

const updateRoleAndPermission = (data: any) => {
  return axiosInstance.put(`${BASE_URL}/roles/permission`, { ...data });
};

const loginPasscode = (data: any) => {
  return axiosInstance
    .post(`${BASE_URL}/roles/login-passcode`, { ...data })
    .then((res: any) => {
      return {
        success: true,
        data: { ...res.data },
      };
    })
    .catch((error: any) => {
      const messageKey = error?.response?.data?.messageKey;

      let message = '';
      switch (messageKey) {
        case 'Forbidden':
          message = 'You don’t have enough perrmission.';
          break;
        case 'WrongPasscode':
          message = 'Passcode is incorrect';
          break;

        default:
          message = '';
          break;
      }

      return {
        success: false,
        data: {
          messageKey,
          message,
        },
      };
    });
};
// ========== END OF STAFF MANAGEMENT ==========

// ========== CUSTOMER MANAGEMENT ==========
const getAllCustomer = () => {
  return axiosInstance.get(`${CUSTOMER_HOST}/customers`);
};

const getCustomerDetails = (data: any) => {
  return axiosInstance.post(`${CUSTOMER_HOST}/customers/info`, { ...data });
};

const createCustomer = (data: any) => {
  return axiosInstance.post(`${CUSTOMER_HOST}/customers`, { ...data }).catch((err: any) => {
    const listErr = err.response.data?.details;
    throw new Error((Array.isArray(listErr) && listErr.join(', ')) || 'Something went wrong!');
  });
};

const updateCustomer = (data: any) => {
  return axiosInstance.put(`${CUSTOMER_HOST}/customers/info`, { ...data }).catch((err: any) => {
    const listErr = err.response.data?.details;
    throw new Error((Array.isArray(listErr) && listErr.join(', ')) || 'Something went wrong!');
  });
};

const deleteCustomer = (data: any) => {
  return axiosInstance.post(`${CUSTOMER_HOST}/customers/info/remove`, {
    ...data,
  });
};

const getCustomerHistory = (customerId: any) => {
  return axiosInstance.get(`${CUSTOMER_HOST}/customers/history/${customerId}`);
};

const getCustomerPointHistory = (customerId: any) => {
  return axiosInstance.get(`${CUSTOMER_HOST}/customers/point-history/${customerId}`);
};

// const getCustomerGiftCards = (customerId: any) => {
//   return axiosInstance.get(`${CUSTOMER_HOST}/customers/gift-card/list/${customerId}`);
// };

const getCustomerGiftCards = (customerId: any) => {
  return axiosInstance.get(`${POS_DOMAIN}/gift-card/customer/${customerId}`);
};

const getGiftCardById = (giftCardId: any) => {
  return axiosInstance.get(`${POS_DOMAIN}/gift-card/${giftCardId}`);
};

const getGiftCardHistory = (giftCardId: any) => {
  return axiosInstance.get(`${POS_DOMAIN}/gift-card/history/${giftCardId}`);
};

const updateGiftCard = (data: any) => {
  return axiosInstance.put(`${POS_DOMAIN}/gift-card`, data);
};

// ========== END OF CUSTOMER MANAGEMENT ==========

// ========== PAYMENT SETTING ==========
const getPaymentInfo = () => {
  const url = `${TENANT_HOST}/tenants/payment-method`;
  return axiosInstance.get(url);
};

const updatePaymentInfo = (data: any) => {
  const url = `${TENANT_HOST}/tenants/payment-method`;
  return axiosInstance.put(url, { ...data });
};
// ========== END OF PAYMENT SETTING ==========

// ========== BUSINESS INFO ==========
const getBusinessInfo = () => {
  const url = `${BUSINESS_HOST}/tenants/business-info/`;
  return axiosInstance.get(url);
};

const updateBusinessInfo = (data: any) => {
  return axiosInstance.put(`${BUSINESS_HOST}/tenants/business-info`, { ...data });
};
// ========== END OF BUSINESS INFO ==========

// ========== CLOVER SETTING ==========
const getCloverSettings = () => {
  return axiosInstance.get(`${TENANT_HOST}/tenants/clover`);
};

const updateCloverSettings = (data: any) => {
  return axiosInstance.put(`${TENANT_HOST}/tenants/clover`, { ...data });
};
// ========== END OF CLOVER SETTING ==========

// ========== CHECKOUT SETTING ==========
const getCheckoutSettings = () => {
  return axiosInstance.get(`${TENANT_HOST}/tenants/checkout-settings`);
};

const updateCheckoutSettings = (data: any) => {
  return axiosInstance.put(`${TENANT_HOST}/tenants/checkout-settings`, { ...data });
};
// ========== END OF CHECKOUT SETTING ==========

// ========== GIFTCARD SETTING ==========
const getAllGiftCard = () => {
  return axiosInstance.get(`${CATALOG_HOST}/catalog/giftcards`);
};
const createGiftCard = (data: any) => {
  return axiosInstance.post(`${CATALOG_HOST}/catalog/giftcards`, { ...data });
};

const updateGiftCardOld = (data: any) => {
  return axiosInstance.put(`${CATALOG_HOST}/catalog/giftcards`, { ...data });
};

const deleteGiftCard = (data: any) => {
  return axiosInstance.delete(`${CATALOG_HOST}/catalog/giftcards`, {
    data,
  });
};
// ========== END OF GIFTCARD SETTING ==========

const getIncomeReport = (data: any) => {
  return axiosInstance.post(`${BASE_URL}/report/income`, { ...data });
};

const businessService = {
  getAllProducts,
  createProduct,
  updateProduct,
  deleteProduct,

  createCategory,
  updateCategory,
  deleteCategory,

  getAllStaff,
  getStaffDetails,
  createStaff,
  updateStaff,
  deleteStaff,
  updateStaffCommission,
  getRoleAndPermission,
  updateRoleAndPermission,
  loginPasscode,

  getAllCustomer,
  getCustomerDetails,
  createCustomer,
  updateCustomer,
  deleteCustomer,
  getCustomerHistory,
  getCustomerPointHistory,
  getCustomerGiftCards,
  getGiftCardById,
  getGiftCardHistory,
  updateGiftCard,

  getPaymentInfo,
  updatePaymentInfo,

  getBusinessInfo,
  updateBusinessInfo,

  getCloverSettings,
  updateCloverSettings,

  getCheckoutSettings,
  updateCheckoutSettings,

  getAllGiftCard,
  createGiftCard,
  updateGiftCardOld,
  deleteGiftCard,

  getIncomeReport,
};

export default businessService;
