// Actions

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import businessService from 'services/business.service';

// Define a type for the slice state
interface GlobalState {
  globalSearch: string;

  customerData: any;

  businessInfoStatus: 'idle' | 'loading' | 'failed';
  businessInfo: {
    PK: string;
    SK: string;
    adminId: string;
    adminUsername: string;
    businessEmail: string;
    businessName: string;
    businessPhone: string;
    countryCode: string;
    currency: string;
    data: string;
    industry: string;
    language: string;
    phoneCode: string;
    timezone: string;
  };

  loadStaffStatus: 'idle' | 'loading' | 'failed';
  listStaff: Array<any>;

  loadServiceStatus: 'idle' | 'loading' | 'failed';
  listService: Array<any>;

  staffList: Array<any>;

  currentStateCheckin: 'staff' | 'service' | 'success';
}

// Define the initial state using that type
const initialState: GlobalState = {
  globalSearch: '',

  customerData: {},

  businessInfoStatus: 'idle',
  businessInfo: {
    PK: '',
    SK: '',
    adminId: '',
    adminUsername: '',
    businessEmail: '',
    businessName: '',
    businessPhone: '',
    countryCode: '',
    currency: '',
    data: '',
    industry: '',
    language: '',
    phoneCode: '',
    timezone: '',
  },

  loadStaffStatus: 'loading',
  listStaff: [],

  loadServiceStatus: 'loading',
  listService: [],

  staffList: [],

  // staffList: [
  //   {
  //     staffId, staffName,
  //     services: [
  //       {serviceId, serviceName}
  //     ]
  //   }
  // ],

  currentStateCheckin: 'staff',
};

// Functions
export const fetchBusinessInfo = createAsyncThunk(
  'global/fetchBusinessInfo',
  // if you type your function argument here
  async () => {
    const response = await businessService.getBusinessInfo();
    return response?.data?.data || {};
  },
);

export const fetchListStaff = createAsyncThunk(
  'checkout/fetchListStaff',
  // if you type your function argument here
  async () => {
    const response = await businessService.getAllStaff();
    return response?.data?.data || [];
  },
);

export const fetchListCustomer = createAsyncThunk(
  'checkout/fetchListCustomer',
  // if you type your function argument here
  async () => {
    const response = await businessService.getAllCustomer();
    return response?.data?.data || [];
  },
);

export const fetchListService = createAsyncThunk(
  'checkout/fetchListService',
  // if you type your function argument here
  async () => {
    const response = await businessService.getAllProducts();
    return response?.data?.data || [];
  },
);

// Global slice
export const globalSlice = createSlice({
  name: 'global',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    incrementByAmount: (state: GlobalState, action: PayloadAction<number>) => {
      state.globalSearch += action.payload;
    },

    updateCustomerData: (state: GlobalState, action: PayloadAction<any>) => {
      state.customerData = { ...action.payload };
    },

    updateStaffList: (state: GlobalState, action: PayloadAction<any>) => {
      state.staffList = [...action.payload];
    },

    updateCurrentStateCheckin: (state: GlobalState, action: PayloadAction<any>) => {
      state.currentStateCheckin = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      // fetch business info
      .addCase(fetchBusinessInfo.pending, (state) => {
        state.businessInfoStatus = 'loading';
      })
      .addCase(fetchBusinessInfo.fulfilled, (state, action) => {
        state.businessInfoStatus = 'idle';
        state.businessInfo = { ...action.payload };
      })
      .addCase(fetchBusinessInfo.rejected, (state) => {
        state.businessInfoStatus = 'failed';
      })
      // fetch data staffs
      .addCase(fetchListStaff.pending, (state) => {
        state.loadStaffStatus = 'loading';
      })
      .addCase(fetchListStaff.fulfilled, (state, action) => {
        state.loadStaffStatus = 'idle';
        state.listStaff = [...action.payload];
      })
      .addCase(fetchListStaff.rejected, (state) => {
        state.loadStaffStatus = 'failed';
      })
      // fetch list service
      .addCase(fetchListService.pending, (state) => {
        state.loadServiceStatus = 'loading';
      })
      .addCase(fetchListService.fulfilled, (state, action) => {
        state.loadServiceStatus = 'idle';
        state.listService = [...action.payload];
      })
      .addCase(fetchListService.rejected, (state) => {
        state.loadServiceStatus = 'failed';
      });
  },
});

export const { incrementByAmount, updateCustomerData, updateCurrentStateCheckin, updateStaffList } = globalSlice.actions;

export default globalSlice.reducer;
