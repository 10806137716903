import useGuard from 'hooks/useGuard';
import Loadable from 'layouts/Loadable';
import MainLayout from 'layouts/MainLayout';
import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const Checkin = Loadable(lazy(() => import('features/checkin')));
const CheckinPage = Loadable(lazy(() => import('features/checkinPage')));
const Notification = Loadable(lazy(() => import('features/checkin/notificatioin')));
const Register = Loadable(lazy(() => import('features/checkin/Register')));
// const Staff = Loadable(lazy(() => import('features/staff')));
// const ServiceScreen = Loadable(lazy(() => import('features/serviceScreen')));
// const SuccessScreen = Loadable(lazy(() => import('features/successScreen')));

export default function RootRoutes() {
  const isAuth = useGuard();

  if (!isAuth) {
    return <></>;
  }

  return (
    <Routes>
      <Route path="" element={<MainLayout />}>
        <Route path="" element={<Checkin />} />
        {/* <Route path="/notification" element={<Notification />} /> */}
        <Route path="/checkin" element={<CheckinPage />} />
        <Route path="/register" element={<Register />} />
      </Route>
    </Routes>
  );
}
