// ----------------------------------------------------------------------
function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}
const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '';
// ----------------------------------------------------------------------
export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),

    checkout: path(ROOTS_DASHBOARD, '/checkout'),
    checkoutDetails: path(ROOTS_DASHBOARD, '/checkout/:checkoutId'),

    transactions: path(ROOTS_DASHBOARD, '/transactions'),
    transactionDetail: path(ROOTS_DASHBOARD, '/transactions/detail'),

    tickets: path(ROOTS_DASHBOARD, '/tickets'),
    ticketDetail: path(ROOTS_DASHBOARD, '/tickets/detail'),
    ticketEdit: path(ROOTS_DASHBOARD, '/tickets/edit'),

    payrolls: path(ROOTS_DASHBOARD, '/payrolls'),
    payrollsByStaff: path(ROOTS_DASHBOARD, '/payrolls/payroll_by_staff'),

    income: path(ROOTS_DASHBOARD, '/income'),
  },
  //report
  report: {
    root: path(ROOTS_DASHBOARD, '/report'),

    payroll: path(ROOTS_DASHBOARD, '/report/payrolls'),
    payrollsByStaff: path(ROOTS_DASHBOARD, '/report/payrolls/payroll_by_staff'),

    transaction: path(ROOTS_DASHBOARD, '/report/transactions'),
    transactionDetail: path(ROOTS_DASHBOARD, '/report/transactions/detail'),

    ticket: path(ROOTS_DASHBOARD, '/report/tickets'),
    ticketDetail: path(ROOTS_DASHBOARD, '/report/tickets/detail'),
    ticketEdit: path(ROOTS_DASHBOARD, '/report/tickets/edit'),

    income: path(ROOTS_DASHBOARD, '/report/income'),
  },
  //business-settings
  business_settings: {
    root: path(ROOTS_DASHBOARD, '/business-settings'),
    services: path(ROOTS_DASHBOARD, '/business-settings/services'),
    staffs: path(ROOTS_DASHBOARD, '/business-settings/staffs'),
    staffs_info: path(ROOTS_DASHBOARD, '/business-settings/staffs/info'),
    staffs_role_permission: path(ROOTS_DASHBOARD, '/business-settings/staffs/role-permission'),
    details: path(ROOTS_DASHBOARD, '/business-settings/details'),
    payment: path(ROOTS_DASHBOARD, '/business-settings/payment'),
    clover: path(ROOTS_DASHBOARD, '/business-settings/clover'),
    checkout_setting: path(ROOTS_DASHBOARD, '/business-settings/checkout_setting'),
    display_setting: path(ROOTS_DASHBOARD, '/business-settings/display_setting'),
    customer_setting: path(ROOTS_DASHBOARD, '/business-settings/customer_setting'),
    giftcard: path(ROOTS_DASHBOARD, '/business-settings/giftcard_setting'),
    admin_setting: path(ROOTS_DASHBOARD, '/business-settings/admin_setting'),
    income_report: path(ROOTS_DASHBOARD, '/business-settings/income_report'),
  },
};
